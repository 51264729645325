import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import styles from "@/components/ui/buttons/v2/ButtonComponent.module.css";
type Variant = "primary" | "secondary" | "outline" | "outline-mono" | "outline-mono-sm" | "outline-mono-dark" | "outline-sm" | "ghost" | "ghost-sm" | "ghost-sm-alt" | "ghost-yellow-sm" | "ghost-yellow-sm-alt";
interface ButtonProps {
  title: string;
  onClick?: () => void;
  variant?: Variant;
  icon?: JSX.Element;
  disabled?: boolean;
  loading?: boolean;
  type?: "button" | "submit";
}
interface VariantStyle {
  backgroundColor: string;
  textColor: string;
  fontFamily: string;
  textTransform: React.CSSProperties["textTransform"];
  paddingVertical: string;
  paddingHorizontal: string;
  iconColor: string;
  spinnerColor: string;
  letterSpacing?: string;
  borderWidth?: string;
  borderColor?: string;
  fontSize?: string;
}
const variantStyles: Record<Variant, VariantStyle> = {
  primary: {
    backgroundColor: "var(--primary-color)",
    textColor: "var(--secondary-color)",
    fontFamily: "var(--font-primary)",
    textTransform: "none",
    paddingVertical: "12px",
    paddingHorizontal: "24px",
    iconColor: "var(--secondary-color)",
    spinnerColor: "var(--secondary-color)"
  },
  secondary: {
    backgroundColor: "var(--secondary-color)",
    textColor: "var(--primary-color)",
    fontFamily: "var(--font-primary)",
    textTransform: "none",
    paddingVertical: "var(--spacing-lg)",
    paddingHorizontal: "var(--spacing-lg)",
    iconColor: "var(--primary-color)",
    spinnerColor: "var(--primary-color)"
  },
  outline: {
    backgroundColor: "transparent",
    textColor: "#ffffff",
    fontFamily: "var(--font-primary)",
    textTransform: "none",
    paddingVertical: "12px",
    paddingHorizontal: "24px",
    iconColor: "#ffffff",
    spinnerColor: "#ffffff",
    borderWidth: "1px",
    borderColor: "rgba(255, 255, 255, 0.5)"
  },
  "outline-mono": {
    backgroundColor: "transparent",
    textColor: "#ffffff",
    fontFamily: "var(--font-mono)",
    textTransform: "uppercase",
    paddingVertical: "12px",
    paddingHorizontal: "24px",
    iconColor: "#ffffff",
    letterSpacing: "0.2em",
    spinnerColor: "#ffffff",
    borderWidth: "1px",
    borderColor: "rgba(255, 255, 255, 0.5)"
  },
  "outline-mono-sm": {
    backgroundColor: "transparent",
    textColor: "#ffffff",
    fontFamily: "var(--font-mono)",
    textTransform: "uppercase",
    fontSize: "var(--font-size-base)",
    paddingVertical: "6px",
    paddingHorizontal: "16px",
    iconColor: "#ffffff",
    spinnerColor: "#ffffff",
    borderWidth: "1px",
    borderColor: "rgba(255, 255, 255, 0.5)"
  },
  "outline-mono-dark": {
    backgroundColor: "transparent",
    textColor: "var(--secondary-color)",
    fontFamily: "var(--font-mono)",
    textTransform: "uppercase",
    paddingVertical: "12px",
    paddingHorizontal: "24px",
    iconColor: "var(--secondary-color)",
    letterSpacing: "0.2em",
    spinnerColor: "var(--secondary-color)",
    borderWidth: "1px",
    borderColor: "var(--secondary-color)"
  },
  "outline-sm": {
    backgroundColor: "transparent",
    textColor: "#ffffff",
    fontFamily: "var(--font-primary)",
    textTransform: "none",
    paddingVertical: "6px",
    paddingHorizontal: "16px",
    iconColor: "#ffffff",
    spinnerColor: "#ffffff",
    borderWidth: "1px",
    borderColor: "rgba(255, 255, 255, 0.5)"
  },
  ghost: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    textColor: "#ffffff",
    fontFamily: "var(--font-primary)",
    textTransform: "none",
    paddingVertical: "12px",
    paddingHorizontal: "24px",
    iconColor: "#ffffff",
    spinnerColor: "#ffffff"
  },
  "ghost-sm": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    textColor: "#ffffff",
    fontFamily: "var(--font-primary)",
    textTransform: "none",
    paddingVertical: "6px",
    paddingHorizontal: "16px",
    iconColor: "#ffffff",
    spinnerColor: "#ffffff"
  },
  "ghost-sm-alt": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    textColor: "#ffffff",
    fontFamily: "var(--font-mono)",
    textTransform: "uppercase",
    fontSize: "var(--font-size-base)",
    paddingVertical: "6px",
    paddingHorizontal: "16px",
    iconColor: "#ffffff",
    spinnerColor: "#ffffff"
  },
  "ghost-yellow-sm": {
    backgroundColor: "rgb(255 248 0 / 81%)",
    textColor: "var(--dark-yellow-color)",
    fontFamily: "var(--font-mono)",
    textTransform: "uppercase",
    fontSize: "var(--font-size-base)",
    paddingVertical: "6px",
    paddingHorizontal: "16px",
    iconColor: "var(--yellow-color)",
    spinnerColor: "var(--yellow-color)"
  },
  "ghost-yellow-sm-alt": {
    backgroundColor: "transparent",
    textColor: "var(--dark-yellow-color)",
    fontFamily: "var(--font-mono)",
    textTransform: "uppercase",
    fontSize: "var(--font-size-base)",
    paddingVertical: "6px",
    paddingHorizontal: "16px",
    iconColor: "var(--yellow-color)",
    spinnerColor: "var(--yellow-color)",
    borderWidth: "1px",
    borderColor: "var(--dark-yellow-color)"
  }
};
const ButtonComponent: React.FC<ButtonProps> = ({
  title,
  onClick,
  variant = "primary",
  icon,
  disabled = false,
  loading = false,
  type = "button"
}) => {
  const [pressed, setPressed] = useState(false);
  const isDisabled = disabled || loading;
  const handleMouseDown = () => {
    if (!isDisabled) {
      setPressed(true);
    }
  };
  const handleMouseUp = () => {
    if (!isDisabled) {
      setPressed(false);
    }
  };
  const pressedStyle: React.CSSProperties = pressed ? {
    transform: "scale(0.96)"
  } : {};
  const {
    backgroundColor,
    textColor,
    fontFamily,
    textTransform,
    paddingVertical,
    paddingHorizontal,
    letterSpacing,
    spinnerColor,
    borderWidth,
    borderColor,
    fontSize
  } = variantStyles[variant];
  const buttonStyle: React.CSSProperties = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "var(--border-radius-xxxl)",
    backgroundColor,
    padding: `${paddingVertical} ${paddingHorizontal}`,
    borderWidth: borderWidth ?? 0,
    textTransform: textTransform ?? undefined,
    borderColor: borderColor ?? undefined,
    borderStyle: "solid",
    cursor: isDisabled ? "not-allowed" : "pointer",
    opacity: isDisabled ? 0.5 : 1,
    transition: "transform 0.1s",
    ...pressedStyle
  };
  const textStyle: React.CSSProperties = {
    color: textColor,
    fontFamily,
    letterSpacing: letterSpacing,
    textTransform: textTransform ?? "none",
    fontSize: fontSize ?? "var(--font-size-xs)",
    display: "flex",
    alignItems: "center"
  };
  return <button onClick={isDisabled ? undefined : onClick} style={buttonStyle} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} disabled={isDisabled} type={type} data-sentry-component="ButtonComponent" data-sentry-source-file="ButtonComponent.tsx">
			{loading ? <FaSpinner className={styles.spinner} style={{
      color: spinnerColor
    }} /> : <>
					{icon && <span style={{
        marginRight: "8px"
      }}>{icon}</span>}
					<span style={textStyle}>{title}</span>
				</>}
		</button>;
};
export default ButtonComponent;