import { Canvas, useFrame } from "@react-three/fiber";
import { useEffect, useMemo, useRef } from "react";
import { Vector2, MathUtils, Color } from "three";
import * as THREE from "three";
import BackgroundV2 from "@/utils/BackgroundV2"; // Make sure to import THREE

const Blob = ({ loading }) => {
	const mesh = useRef();
	const hover = useRef(false);

	const uniforms = useMemo(
		() => ({
			u_intensity: { value: 0.3 },
			u_time: { value: 0.0 },
			u_resolution: {
				value: new Vector2(window.innerWidth, window.innerHeight),
			},
			fogColor: { value: new THREE.Color(0x05293d) },
			fogNear: { value: 8.0 },
			fogFar: { value: 30.0 },
			u_colorTransition: { value: 0 }, // Transition factor for color blending
		}),
		[],
	);

	useEffect(() => {
		const handleResize = () => {
			uniforms.u_resolution.value.set(window.innerWidth, window.innerHeight);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [uniforms.u_resolution.value]);

	useFrame((state) => {
		const { clock } = state;
		mesh.current.material.uniforms.u_time.value = clock.getElapsedTime();

		const HOVER_INTENSITY = 0.2;
		const NORMAL_INTENSITY = loading ? 0.2 : 0.01;
		const LERP_SPEED = 0.01;

		const targetIntensity = hover.current ? HOVER_INTENSITY : NORMAL_INTENSITY;

		mesh.current.material.uniforms.u_intensity.value = MathUtils.lerp(
			mesh.current.material.uniforms.u_intensity.value,
			targetIntensity,
			LERP_SPEED,
		);

		const COLOR_LERP_SPEED = 0.01;

		// Update u_colorTransition
		uniforms.u_colorTransition.value = MathUtils.lerp(
			uniforms.u_colorTransition.value,
			loading ? 1 : 0,
			COLOR_LERP_SPEED,
		);
	});

	// Vertex Shader (same as before)
	const vertexShader = `
    uniform float u_intensity;
    uniform float u_time;

    varying vec2 vUv;
    varying float vDisplacement;
    varying float vFogDepth;

    // Classic Perlin Noise 3D
    vec4 permute(vec4 x) { return mod(((x*34.0)+1.0)*x, 289.0); }
    vec4 taylorInvSqrt(vec4 r) { return 1.79284291400159 - 0.85373472095314 * r; }
    vec3 fade(vec3 t) { return t*t*t*(t*(t*6.0-15.0)+10.0); }

    float cnoise(vec3 P){
      vec3 Pi0 = floor(P); // Integer part for indexing
      vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
      Pi0 = mod(Pi0, 289.0);
      Pi1 = mod(Pi1, 289.0);
      vec3 Pf0 = fract(P); // Fractional part for interpolation
      vec3 Pf1 = Pf0 - vec3(1.0);
      vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
      vec4 iy = vec4(Pi0.yy, Pi1.yy);
      vec4 iz0 = Pi0.zzzz;
      vec4 iz1 = Pi1.zzzz;

      vec4 ixy = permute(permute(ix) + iy);
      vec4 ixy0 = permute(ixy + iz0);
      vec4 ixy1 = permute(ixy + iz1);

      vec4 gx0 = ixy0 / 7.0;
      vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
      gx0 = fract(gx0);
      vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
      vec4 sz0 = step(gz0, vec4(0.0));
      gx0 -= sz0 * (step(0.0, gx0) - 0.5);
      gy0 -= sz0 * (step(0.0, gy0) - 0.5);

      vec4 gx1 = ixy1 / 7.0;
      vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
      gx1 = fract(gx1);
      vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
      vec4 sz1 = step(gz1, vec4(0.0));
      gx1 -= sz1 * (step(0.0, gx1) - 0.5);
      gy1 -= sz1 * (step(0.0, gy1) - 0.5);

      vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
      vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
      vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
      vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
      vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
      vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
      vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
      vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

      vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
      g000 *= norm0.x;
      g010 *= norm0.y;
      g100 *= norm0.z;
      g110 *= norm0.w;
      vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
      g001 *= norm1.x;
      g011 *= norm1.y;
      g101 *= norm1.z;
      g111 *= norm1.w;

      float n000 = dot(g000, Pf0);
      float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
      float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
      float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
      float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
      float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
      float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
      float n111 = dot(g111, Pf1);

      vec3 fade_xyz = fade(Pf0);
      vec4 n_z = mix(vec4(n000, n100, n010, n110),
                     vec4(n001, n101, n011, n111), fade_xyz.z);
      vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
      float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
      return 2.2 * n_xyz;
    }

    void main() {
      vUv = uv;
      vDisplacement = cnoise(position + vec3(1.0 * u_time));
      vec3 newPosition = position + normal * (u_intensity * vDisplacement * 0.8);

      vec4 mvPosition = modelViewMatrix * vec4(newPosition, 1.0);
      gl_Position = projectionMatrix * mvPosition;
      vFogDepth = -mvPosition.z;
    }
  `;

	// Fragment Shader (updated)
	const fragmentShader = `
    uniform float u_intensity;
    uniform float u_time;
    uniform vec2 u_resolution;

    varying vec2 vUv;
    varying float vDisplacement;

    uniform vec3 fogColor;
    uniform float fogNear;
    uniform float fogFar;

    uniform float u_colorTransition; // Transition factor for color blending

    varying float vFogDepth;

    #define resolution 1.0 / 2.0
    #define background 0.0

    float luma(in vec4 color) {
        return dot(color.rgb, vec3(0.299, 0.587, 0.114));
    }

    float dither4x4(in vec2 position, in float brightness) {
        int x = int(mod(position.x, 4.0));
        int y = int(mod(position.y, 4.0));
        int index = x + y * 4;
        float limit = 0.0;

        if (x < 8) {
            if (index == 0) limit = 0.0625;
            else if (index == 1) limit = 0.5625;
            else if (index == 2) limit = 0.1875;
            else if (index == 3) limit = 0.6875;
            else if (index == 4) limit = 0.8125;
            else if (index == 5) limit = 0.3125;
            else if (index == 6) limit = 0.9375;
            else if (index == 7) limit = 0.4375;
            else if (index == 8) limit = 0.25;
            else if (index == 9) limit = 0.75;
            else if (index == 10) limit = 0.125;
            else if (index == 11) limit = 0.625;
            else if (index == 12) limit = 1.0;
            else if (index == 13) limit = 0.5;
            else if (index == 14) limit = 0.875;
            else if (index == 15) limit = 0.375;
            limit *= 0.75;
        }

        return brightness < limit ? 0.0 : 1.0;
    }

    vec4 dither4x4(in vec2 position, in vec4 color) {
        return vec4(color.rgb * dither4x4(position, luma(color)), 1.0);
    }

    void main() {
      float distort = 2.0 * vDisplacement * u_intensity;

      // Compute initial color components based on vUv and distort
      float r = abs(vUv.x - 0.5) * 2.0 * (1.0 - distort);
      float g = abs(vUv.y - 0.5) * 2.0 * (1.0 - distort);
      float b = 1.0 * (1.0 - distort);

      // Define initial base color (white) and loading base color (orange)
      vec3 initialBaseColor = vec3(1.0, 1.0, 1.0);    // White
      vec3 loadingBaseColor = vec3(0.50, 0.50, 1.0);   // Purple

      // Interpolate base color based on u_colorTransition
      vec3 baseColor = mix(initialBaseColor, loadingBaseColor, u_colorTransition);

      // Apply base color to the computed color components
      vec3 color = vec3(r, g, b) * baseColor;

      vec4 c = vec4(color, 1.0);
      vec2 fragCoord = gl_FragCoord.xy;

      c = max(dither4x4(fragCoord * resolution, c), background);
      float alpha = length(c.rgb);

      // Calculate the fog factor
      float fogFactor = smoothstep(fogNear, fogFar, vFogDepth);

      // Blend the color with the fog color
      vec3 finalColor = mix(c.rgb, fogColor, fogFactor);

      gl_FragColor = vec4(finalColor, 0.8);
    }
  `;

	return (
		<mesh
			ref={mesh}
			position={[0, -9, 5]}
			scale={[1.0, 1.0, 1.0]}
			onPointerOver={() => (hover.current = true)}
			onPointerOut={() => (hover.current = false)}
		>
			<icosahedronGeometry args={[8, 40]} />
			<shaderMaterial
				fragmentShader={fragmentShader}
				vertexShader={vertexShader}
				uniforms={uniforms}
				wireframe={false}
				transparent={true}
				depthWrite={false}
				depthTest={false}
			/>
		</mesh>
	);
};

const BlobScene = ({ loading, showBlob = true }) => {
	return (
		<Canvas
			gl={{ alpha: true }}
			camera={{ position: [0.0, 0.0, 8.0], fov: 50 }}
			onCreated={({ gl }) => {
				gl.setClearColor(0x000000, 0);
			}}
		>
			<BackgroundV2 loading={loading} />
			{showBlob && <Blob loading={loading} />}
		</Canvas>
	);
};

export default BlobScene;
