// Background.js

import { useRef, useMemo, useEffect } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { MathUtils } from 'three'

const BackgroundV2 = ({ loading }) => {
  const mesh = useRef()
  const { camera, viewport } = useThree()
  const { width: viewportWidth, height: viewportHeight } = viewport

  const uniforms = useMemo(
    () => ({
      u_time: { value: 0 },
      u_colorTransition: { value: 0 },
    }),
    [],
  )

  useFrame((state) => {
    uniforms.u_time.value = state.clock.getElapsedTime()

    const COLOR_LERP_SPEED = 0.01
    uniforms.u_colorTransition.value = MathUtils.lerp(
      uniforms.u_colorTransition.value,
      loading ? 1 : 0,
      COLOR_LERP_SPEED,
    )
  })

  // Calculate plane size based on camera frustum
  const z = -1 // Position the plane behind the blob
  const { fov, aspect } = camera
  const cameraZ = camera.position.z

  // Calculate height and width at the given z distance
  const height = 2 * Math.tan(THREE.MathUtils.degToRad(fov / 2)) * (cameraZ - z)
  const width = height * aspect

  const fragmentShader = `
  uniform float u_time;
  uniform float u_colorTransition;

  #define S(a,b,t) smoothstep(a,b,t)

  mat2 Rot(float a) {
      float s = sin(a);
      float c = cos(a);
      return mat2(c, -s, s, c);
  }

  // Noise functions
  vec2 hash(vec2 p) {
      p = vec2(dot(p, vec2(2127.1, 81.17)), dot(p, vec2(1269.5, 283.37)));
      return fract(sin(p) * 43758.5453);
  }

  float noise(vec2 p) {
      vec2 i = floor(p);
      vec2 f = fract(p);
      vec2 u = f * f * (3.0 - 2.0 * f);

      float n = mix(
          mix(dot(-1.0 + 2.0 * hash(i + vec2(0.0, 0.0)), f - vec2(0.0, 0.0)),
              dot(-1.0 + 2.0 * hash(i + vec2(1.0, 0.0)), f - vec2(1.0, 0.0)), u.x),
          mix(dot(-1.0 + 2.0 * hash(i + vec2(0.0, 1.0)), f - vec2(0.0, 1.0)),
              dot(-1.0 + 2.0 * hash(i + vec2(1.0, 1.0)), f - vec2(1.0, 1.0)), u.x),
          u.y);
      return 0.5 + 0.5 * n;
  }

  varying vec2 vUv;

  void main() {
    vec2 uv = vUv;
    vec2 tuv = uv - 0.5;

    // Rotate with noise
    float degree = noise(vec2(u_time * 0.1, tuv.x * tuv.y));
    tuv *= Rot(radians((degree - 0.5) * 720.0 + 180.0));

    // Wave warp with sin
    float frequency = 5.0;
    float amplitude = 30.0;
    float speed = u_time * 0.5;
    tuv.x += sin(tuv.y * frequency + speed) / amplitude;
    tuv.y += sin(tuv.x * frequency * 1.5 + speed) / (amplitude * 0.5);

    // Define initial colors (dark blue and turquoise)
    vec3 colorInitial1 = vec3(0.0, 0.45, 0.45); // Lighter Turquoise
    vec3 colorInitial2 = vec3(0.0, 0.1, 0.2);   // Very Dark Blue

    // Define loading colors (yellow, orange, lime)
    vec3 colorLoading1 = vec3(0.17, 0.32, 0.39);   // Orange
    vec3 colorLoading2 = vec3(0.05, 0.12, 0.15);   // Lime

    // Interpolate colors based on u_colorTransition
    vec3 color1 = mix(colorInitial1, colorLoading1, u_colorTransition);
    vec3 color2 = mix(colorInitial2, colorLoading2, u_colorTransition);

    // Use color1 and color2 in your shader computations
    vec3 layer1 = mix(color1, color2, S(-0.3, 0.2, (tuv * Rot(radians(-5.0))).x));

    // Define additional initial and loading colors for layer2
    vec3 colorInitial3 = vec3(0.0, 0.2, 0.4);   // Medium Dark Blue
    vec3 colorInitial4 = vec3(0.0, 0.35, 0.55); // Slightly Lighter Blue
    vec3 colorLoading3 = vec3(0.55, 0.17, 0.88);   // Yellow
    vec3 colorLoading4 = vec3(0.0, 0.1, 0.2);   // Purple

    // Interpolate colors for layer2
    vec3 color3 = mix(colorInitial3, colorLoading3, u_colorTransition);
    vec3 color4 = mix(colorInitial4, colorLoading4, u_colorTransition);

    vec3 layer2 = mix(color3, color4, S(-0.3, 0.2, (tuv * Rot(radians(-5.0))).x));

    // Adding lighter areas using noise
    float lightNoise = noise(tuv * 5.0 + u_time * 0.1);

    // Define initial and loading light colors
    vec3 lightColorInitial = vec3(0.15, 0.5, 0.7);  // Slightly Brighter Light Color
    vec3 lightColorLoading = vec3(0.55, 0.17, 0.88);   // Purple

    // Interpolate light color based on u_colorTransition
    vec3 lightColor = mix(lightColorInitial, lightColorLoading, u_colorTransition);

    vec3 darkColor = mix(layer1, layer2, S(0.5, -0.3, tuv.y));
    vec3 finalComp = mix(darkColor, lightColor, smoothstep(0.7, 1.0, lightNoise));

    gl_FragColor = vec4(finalComp, 0.6);
}

`

  const vertexShader = `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `

  return (
    <mesh ref={mesh} position={[0, 0, z]} renderOrder={-1}>
      <planeGeometry args={[width, height]} />
      <shaderMaterial
        fragmentShader={fragmentShader}
        vertexShader={vertexShader}
        uniforms={uniforms}
        depthWrite={false}
        depthTest={false}
        side={THREE.DoubleSide}
      />
    </mesh>
  )
}

export default BackgroundV2
